import React from 'react'
import Navbar from "../inc/Navbar";
import ScrollToTop from "react-scroll-to-top";
import {Link} from "react-router-dom";
import "./About.css";
function About() {
  return (
    <div>
      <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
     
      
        <ScrollToTop smooth top="100" color="Darkblue" />
        <div className="gamesoly">
          <br/>
          
          <br/>
        <div className="album py-2">
        
        <div className="container">

            <div className="row">
        <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li style={{color:"white"}} className="breadcrumb-item"><Link style={{color:"white"}} to="/" ><svg style={{color:"white"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg> BACK</Link></li>
    <li style={{color:"white"}} className="breadcrumb-item"><Link style={{color:"white"}} to="/Gallery" >Gallery</Link></li>
    <li style={{color:"white"}} className="breadcrumb-item"><Link style={{color:"white"}} to="/Contact" >Contact</Link></li>
    <li style={{color:"white"}} className="breadcrumb-item active"  aria-current="page">About us</li>
  </ol>
</nav>
</div>
</div>
</div>
        <div className="album py-1">
        
        <div className="container">

            <div className="row">
        
          
         
        
          <br/>
          </div>
          </div>
          </div>
        </div>
        <br/>
        <center>
          <h2><b>ABOUT THE SKILL FOUNDATION</b></h2>
        </center>
        <div className="album py-1">
        
        <div className="container">

            <div className="row">
          
            <p className="texts">The Skill Foundation is a charitable trust. It is concerned with developing SKILL to a desired standard by instruction and practice. Its main focus is to develop human resources and preserve local arts, culture and undertake any activity of charitable nature.
The Skill Foundation encourage, promote, sponsor and conduct activities in Games and sports. It pays attention on Personality development and programmes in the field of cultural activity, social welfare. It organizes various training in music and fine arts to develop the power of observation and this turn will give better insight into literature, philosophy and science.
It imparts training in domestic hygiene, domestic economy, agriculture, carpentry, clay modelling, basket weaving, cane works spinning, bamboo work, tailoring and other cottage industries. The Skill Foundation organize public lectures, debates, hold concerts, competitions, programs, symposiums, work-shops, seminars, conferences, etc.</p>
          
           
       

              </div>
              </div></div>

           
    </div>
  )
}

export default About;
