
import React from "react";
import Navbar from "../inc/Navbar";


function Over () {
    return(
        <>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="album py-5">
        
        <div className="container">

            <div className="row">

                <h3 style={{color:"Darkblue"}} ><b>Online and offline form fill up for new registration of GAMES Olympiad 2023 is CLOSED. For any query kindly contact The Skill Foundation (Contact No: 9734869861). Thank you.</b></h3>
                </div>
                </div>
                </div>
        </>
    );
};

export default Over