import React from 'react'
import  Navbar  from "../inc/Navbar";
import Syllabus from './Syllabus';

import {Link} from "react-router-dom";

function Syllabusgames() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className='backnew'>
                <div className="album py-1">
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Syllabus of Games Olympiad</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        <div className="album py-2">

<div className="container">
        <center>
        <br/>
        <br/>
        <h1><b>SYLLABUS OF GAMES OLYMPIAD</b></h1>
        </center>
        </div>
        </div>
        <Syllabus/>
<br/>
<br/>


    </div>
  )
}

export default Syllabusgames