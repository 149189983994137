import React, { useState, useEffect }  from 'react';

import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from './PaginationOld';
import {db} from '../../firebase'
import Navbar from '../inc/Navbar';
import "./Gallery.css";
import ScrollToTop from "react-scroll-to-top";

import Videogallery from "../pages/Videogallery";

function Gallery(props) {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(40);
    const [pagination, setPagination] =useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start,  end) =>{
        setPagination({start: start, end: end});
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [gallery, setGallery] = useState([])
    const navigate = useNavigate()

    const galleryCollectionRef = collection(db, "gallery");
    useEffect(() => {

        const getGallery = async () => {
            const data = await getDocs(galleryCollectionRef);
            setGallery(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        }

        getGallery()
    }, [])
    return (
        
<>
<Navbar/>
      
    <br/>

            <ScrollToTop smooth top="100" color="Darkblue"/>
{/* <img src={Outdoor} class="img-fluid"   alt="Outdoor"></img> */}




<div className="album py-2">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" class="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                               
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>

<div className="album py-0">
        
        <div className="container">

            <div className="row">
            {gallery.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) 
      

      
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((olympiad) => { return (
       
            <div className="col-md-6 mt-4" >
                        <div className="card mb-4 border-White box shadow">
                        <img  className="card-img-top" src={olympiad.img}   alt="Floor" />
                            <div className="card-body">
                                <center>
                                <h6 style={{color:"Darkblue"}} className="card-text"> <b>{ olympiad.title} </b></h6>
                                </center>
<p className="card-text mb-2">{olympiad.dis}</p>

                                
                                
                                <div className="d-flex justify-content-between align-items-center">
                              
                                        {/* <button type="button" className="btn btn btn-dark">
                                        {website1.workf} 
                                        </button>  */}

                                       
                                    
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={gallery.length}
                /> */}
                            </div>
                            </div>

                            <Videogallery/>
</>

);
}

export default Gallery;