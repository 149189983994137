
import './App.css';
import React, { useEffect} from "react";
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import About from "./components/pages/About";
import Gallerypage from "./components/pages/Gallerypage";
import Olympiad from "./components/pages/Olympiad";
import Award from "./components/pages/Award";
import Others from "./components/pages/Others";
import Shipping from "./components/pages/Shipping";
import Refund from "./components/pages/Refund";
import Book from "./components/pages/Book";
import Payment from "./components/pages/Payment";
import Over from "./components/pages/Over";

import Post from "./components/Post";
import Terms from "./components/pages/Terms";
import Privacy from "./components/pages/Privacy";


import Footer from "./components/inc/Footer";
import ReactGA from "react-ga"
import Marksheet from './components/pages/Marksheet';
import Syllabusgames from './components/pages/Syllabusgames';
import Preques from './components/pages/Preques';
import Price from './components/pages/Price';








function App() {
  useEffect(() =>{
    ReactGA.initialize('UA-262679316-1')
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log("test");
  }, [])
 
  return (
    <Router>
      
  
     
        <div>
      
         
          
          <Routes>
     
         
          <Route axact path="/" element={<Home/>}/>
            <Route axact path="/contact" element={<Contact/>}/>
            <Route axact path="/aboutus" element={<About/>}/>
            <Route axact path="/Gallery" element={<Gallerypage/>}/>
              <Route axact path="/awardceremony2023" element={<Award/>}/>
              <Route axact path="/Others" element={<Others/>}/>
              <Route axact path="/Shipping" element={<Shipping/>}/>
              <Route axact path="/Refund" element={<Refund/>}/>
              <Route axact path="/book" element={<Book/>}/>
               <Route axact path="/Payment" element={<Payment/>}/>
               <Route axact path="/Application-over" element={<Over/>}/>
               <Route axact path="/marksheet" element={<Marksheet/>}/>
              
            <Route axact path="/Post" element={<Post/>}/>
            <Route axact path="/Terms" element={<Terms/>}/>
            <Route axact path="/Privacy" element={<Privacy/>}/>
            <Route axact path="/Pricing" element={<Price/>}/>
        

            
            
              <Route axact path="/GamesOlympiad" element={<Olympiad/>}/>

              <Route axact path="/syllabus-of-gamesolympiad" element={<Syllabusgames/>}/>
              <Route axact path="/previous-year-question" element={<Preques/>}/>
             
    </Routes>
    <Footer/>
  
    </div>
   
    </Router>
  );
}

export default App;
