import React from 'react'
import Navbar from "../inc/Navbar";
import { Link } from "react-router-dom";

import Bracureolympiad from "../images/CLASS_II_GAMES OLYMPIAD_2023_RESULT.pdf"
import Bracureolympiadresult from "../images/CLASS_III_GAMES OLYMPIAD_2023_RESULT.pdf"
import Bracureolympiadresultiii from "../images/CLASS_IV_GAMES OLYMPIAD_2023_RESULT.pdf"
import Bracureolympiadresultiv from "../images/CLASS_V_GAMES OLYMPIAD_2023_RESULT.pdf"
import result2 from "../images/gamesii.pdf";
import result3 from  "../images/gamesiii.pdf";
import result4 from "../images/gamesiv.pdf";


function Marksheet() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <div className="p-2 mb-0 bg-primary text-dark">
        <br />
        <br />
        <br />
        <br />
        <div className="album py-2">

          <div className="container">
            <nav style={{ color: "white" }} aria-label="breadcrumb">
              <ol style={{ color: "marron" }} className="breadcrumb">
                <li style={{ color: "maroon" }} className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li style={{ color: "white" }} className="breadcrumb-item active" aria-current="page">Games Olympiad Marksheet 2023</li>
              </ol>
            </nav>
          </div>
        </div>
        <br />

        <br />

      </div>
      <br />
      <br />
      <center>
        <h3 style={{ color: "darkblue" }}> <b>Games Olympiad Marksheet 2023</b></h3>
      </center>
      <br />
      <br />
      <div className="album py-2">

        <div className="container">

          <div className="row">
            <div className="col-md-3 mt-8" >
              <div className="d-grid gap-3">
                <a className="btn-grad16 btn-sm mb-2" target="_blank" rel="noopener noreferrer" href={Bracureolympiad} >All Marksheet(SPR)For Class II <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "white" }} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                </svg></a>
              </div>
            </div>
            <div className="col-md-3 mt-8" >
              <div className="d-grid gap-3">
                <a className="btn-grad16 btn-sm mb-2" target="_blank" rel="noopener noreferrer" href={Bracureolympiadresult} >All Marksheet(SPR)For Class III <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "white" }} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                </svg></a>
              </div>
            </div>
            <div className="col-md-3 mt-8" >
              <div className="d-grid gap-2">
                <a className="btn-grad16 btn-sm mb-2" target="_blank" rel="noopener noreferrer" href={Bracureolympiadresultiii} >All Marksheet(SPR)For Class IV <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "white" }} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                </svg></a>
              </div>
            </div>
            <div className="col-md-3 mt-8" >
              <div className="d-grid gap-2">
                <a className="btn-grad16 btn-sm mb-2" target="_blank" rel="noopener noreferrer" href={Bracureolympiadresultiv} >All Marksheet(SPR)For Class V <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "white" }} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                </svg></a>
              </div>
            </div>

            {/* <div className="col-md-2 mt-8" >
<div className="d-grid gap-3">
<button onClick={() => {downloadFileAtURL (CSV_FILE_URL)}} className="btn btn-info btn-sm" >RESULT <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"white"}} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
</svg></button> 
</div>
</div> */}


          </div>
        </div>
      </div>
      <br />

      <hr />
      <br />
      <center>
        <h3 style={{ color: "darkblue" }}> <b>Games Olympiad Marksheet 2022</b></h3>
      </center>

      <div className="album py-2">

        <div className="container">

          <div className="row">
            <div className="col-md-3 mt-3" >
              <div className="d-grid gap-3">
                <a className="btn-grad16 btn-sm mb-2" target="_blank" rel="noopener noreferrer" href={result2} >Result II <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "white" }} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                </svg></a>
              </div>
            </div>

            <div className="col-md-3 mt-3" >
              <div className="d-grid gap-3">
                <a className="btn-grad16 btn-sm mb-2" target="_blank" rel="noopener noreferrer" href={result3} >Result III <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "white" }} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                </svg></a>
              </div>
            </div>
            <div className="col-md-3 mt-3" >
              <div className="d-grid gap-2">
                <a className="btn-grad16 btn-sm mb-2" target="_blank" rel="noopener noreferrer" href={result4} >Result IV  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "white" }} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                </svg></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  )
}

export default Marksheet