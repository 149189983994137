import React from 'react'
import Navbar from '../inc/Navbar'
import Previous from "../pages/Previous";
import Question2023 from './Question2023';
import { Link } from "react-router-dom";

function Preques() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />

            <br />
            <div className='backnew'>
                <div className="album py-1">
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Previous Year Question</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />

            <Previous />
            <Question2023 />

            <br />
        </div>
    )
}

export default Preques