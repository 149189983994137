import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from './PaginationOld';
import {db} from '../../firebase'
import ScrollToTop from "react-scroll-to-top";


import ReactPlayer from "react-player";

function Videogallery() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [videogallery, setVideogallery] = useState([])
      const navigate = useNavigate()
  
      const videogalleryCollectionRef = collection(db, "videogallery");
      useEffect(() => {
  
          const getVideogallery = async () => {
              const data = await getDocs(videogalleryCollectionRef);
              setVideogallery(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getVideogallery()
      }, [])
    return (
      
<>




<ScrollToTop smooth top="100" color="maroon" />


<br/>
<div className="album py-1">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" class="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
            {videogallery.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) 
      
       
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((theskillfoundation) => { return (
       
            <div className="col-md-4 mt-8" >
                        <div className=" mb-4 ">
                                  <ReactPlayer   width='350px' height='240px' url={theskillfoundation.video}/>
                   
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b>{theskillfoundation.title}</b></h6>
                                <p className="card-text">{theskillfoundation.des}</p>
                                {/* <h5 style={{color: "Darkblue"}}className="card-text"> ₹{website.saleprice} </h5>
                                <p style={{color: "Gray"}}className="card-text mb-2"> M.R.P.: <del> ₹{website.delete}</del> </p> */}
                               

                               
                    
                                <div className="d-flex justify-content-between align-items-center">
                              
                               
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={videoogallery.length}
                /> */}
                            </div>
                            </div>

</>
        
        );
    }
    
    export default Videogallery;