

import React from 'react'


import { Link } from "react-router-dom";
import Notice from "../pages/Notice";
import "./About.css";
 



import "./Aboutpage.css";
import { Button } from "react-bootstrap";
function About() {
  return (
    <div>







        <div className="album py-2">

          <div className="container">

            <div className="row">





              <div className="col-md-2 mt-8" >
                <div class="card   mb-4">
                  <Link to="/GamesOlympiad">
                    <div className="d-grid gap-2">
                      <Button variant="success" type="Submit">
                        <h4> Games Olympiad </h4> <div className="spinner-grow text-light" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </Button>
                    </div>
                  </Link>
                  <Link to="/Gallery">
                    <div className="d-grid gap-3">

                      <button className="btn btn-secondary">Gallery</button>

                    </div>
                  </Link>
                  <Link to="/awardceremony2023">
                    <div className="d-grid gap-2">
                      <Button variant="danger" type="Submit">
                        Award
                      </Button>
                    </div>
                  </Link>
                  <Link to="/Others">
                    <div className="d-grid gap-2">

                      <Button variant="primary" type="Submit">
                        Others
                      </Button>
                    </div>
                  </Link>


                 















                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book-half" viewBox="0 0 16 16">
  <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
</svg>  */}


                </div>

              </div>
              <div className="col-md-7 mt-8">
                <div class="card  bg-white mb-3">
                  <div style={{ color: "white" }} className="card-header bg-secondary">About the Organization</div>
                  <div className="card-body">
                    <p style={{ color: "Darkblue" }} className="texts">The Skill Foundation is a charitable trust. It is concerned with developing SKILL to a desired standard by instruction and practice. Its main focus is to develop human resources and preserve local arts, culture and undertake any activity of charitable nature.
                      The Skill Foundation encourage, promote, sponsor and conduct activities in Games and sports. It pays attention on Personality development and programmes in the field of cultural activity, social welfare. It organizes various training in music and fine arts to develop the power of observation and this turn will give better insight into literature, philosophy and science.
                      It imparts training in domestic hygiene, domestic economy, agriculture, carpentry, clay modelling, basket weaving, cane works spinning, bamboo work, tailoring and other cottage industries. The Skill Foundation organize public lectures, debates, hold concerts, competitions, programs, symposiums, work-shops, seminars, conferences, etc.</p>
                  
                    {/* <img src={Theskillfoundation} className="img-fluid" alt="Theskillfoundation" /> */}
                    {/* <h5 className="card-title">Class-II Syllabus</h5> */}


                    


                    <div className="d-flex justify-content-between align-items-center">










                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book-half" viewBox="0 0 16 16">
  <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
</svg>  */}


                    </div>
                  </div>
                </div>

              </div>


              <div className="col-md-3 mt-8" >
                <div className="card text-dark bg-white mb-3">
                  <div style={{ color: "Darkblue" }} className="card-header bg-light">Notice Board</div>


                  <Notice />
                  {/* <h5 className="card-title">Class-II Syllabus</h5> */}


                  <br />


                  <div className="d-flex justify-content-between align-items-center">










                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book-half" viewBox="0 0 16 16">
  <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
</svg>  */}



                  </div>
                </div>

              </div>
            </div>
          </div></div>



      </div>
      )
}

      export default About;
