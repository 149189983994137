

import React from "react";

import Navbar from "../inc/Navbar";
import Qrcode from "../images/qr.jpeg";

function Payment() {
    return (
        <>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="album py-2">

                <div className="container">

                    <div className="row">






                        <div className="col-md-7 mt-8">
                            <div class="card  bg-white mb-3">
                                <div style={{ color: "white" }} className="card-header bg-secondary"><center>Through bank / online account transfer</center></div>
                                <div className="card-body">
                                    <p style={{ color: "Darkblue" }} className="texts mb-2"><u><b><center>Fees receving account details of treasurer</center></b></u></p>
                                    <p style={{ color: "Darkblue" }} className="texts mb-2"><center>Account No. : <b>10200004821667</b></center></p>
                                    <p style={{ color: "Darkblue" }} className="texts mb-2 "><center>IFSC : <b>BDBL0001074</b></center></p>
                                    <p style={{ color: "Darkblue" }} className="texts mb-2 "><center>Name ofaccount holder : <b>SOUVIK DUTTA</b></center></p>
                                
                                    <p style={{ color: "Darkblue" }} className="texts mb-2 "><center>Bank Name : <b>Bandhan Bank</b></center></p>

                                    <p style={{ color: "Darkblue" }} className="texts mb-3 "><center>Branch : <b>Natna Branch</b></center></p>

                                    <p  className="texts">ফর্ম জমা দেওয়ার পর অনলাইন ব্যাঙ্ক অ্যাকাউন্ট ট্রান্সফারের মাধ্যমে উপরের ব্যাঙ্ক অ্যাকাউন্টে ফিস প্রদান করতে হবে। উপরের অ্যাকাউন্টে সফলভাবে ফিস প্রদানের পরে, অভিভাবক বা স্কুলকে অবশ্যই পূরণ করা pdf ফর্ম এবং ব্যাঙ্কের পেমেন্ট রসিদের ছবি বা স্ক্রিনশট দ্য স্কিল ফাউন্ডেশনে পাঠাতে হবে <a href="mailto: wbtheskillfoundation@gmail.com">wbtheskillfoundation@gmail.com</a>  এই ইমেলের মাধ্যমে। </p>
                                    <p  className="texts">After form submission pay the fees to the above bank account through online bank account transfer. After successful transaction of fees to the above account, guardian or school must has to send the filled in pdf form and photo or screenshot of bank payment receipt to The Skill Foundation through the email address:<a href="mailto: wbtheskillfoundation@gmail.com">wbtheskillfoundation@gmail.com</a>  </p>

                                    {/* <h5 className="card-title">Class-II Syllabus</h5> */}


                                  


                                    <div className="d-flex justify-content-between align-items-center">











                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="col-md-5 mt-8">
                            <div class="card  bg-white mb-3">
                                <div style={{ color: "white" }} className="card-header bg-secondary"><center>QR CODE Payment Methods</center></div>
                                <div className="card-body">
                                <img src={Qrcode} className="img-fluid mb-3" alt="Theskillfoundation" />
                                    <p  className="texts">ফর্ম জমা দেওয়ার পর UPI এর মাধ্যমে ফিস প্রদানের জন্য উপরের QR কোডটি স্ক্যান করতে হবে। উপরের UPI এ সফলভাবে ফিস প্রদানের পরে, অভিভাবক বা স্কুলকে অবশ্যই পূরণ করা pdf ফর্ম এবং UPI পেমেন্টের ছবি বা স্ক্রিনশট দ্য স্কিল ফাউন্ডেশনে পাঠাতে হবে <a href="mailto: wbtheskillfoundation@gmail.com">wbtheskillfoundation@gmail.com</a> এই ইমেলের মাধ্যমে। </p>
                                    <p  className="texts">After form submission scan the above QR code to pay the fees through UPI. After successful transaction of fees to the above UPI, guardian or school must has to send the filled in pdf form and photo or screenshot of UPI payment receipt to The Skill Foundation through the email address: <a href="mailto: wbtheskillfoundation@gmail.com ">wbtheskillfoundation@gmail.com</a>  </p>

                                    <br />
                                  
                                    {/* <h5 className="card-title">Class-II Syllabus</h5> */}


                                    <br />


                                    <div className="d-flex justify-content-between align-items-center">











                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div></div>

        </>
    );
}

export default Payment
