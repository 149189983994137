import React, { useState, useEffect }  from 'react';

import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from './PaginationOld';
import {db} from '../../firebase'

import "./Gallery.css";




function Gallery2023(props) {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(40);
    const [pagination, setPagination] =useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start,  end) =>{
        setPagination({start: start, end: end});
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [gallery, setGallery] = useState([])
    const navigate = useNavigate()

    const galleryCollectionRef = collection(db, "GALLERY-2023-PRIZE");
    useEffect(() => {

        const getGallery = async () => {
            const data = await getDocs(galleryCollectionRef);
            setGallery(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        }

        getGallery()
    }, [])
    return (
        
<>







<div className="album py-3">
        
        <div className="container">

            <div className="row">
            {gallery.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.des.toLowerCase().includes(searchTerm.toLowerCase()) 
      

      
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((olympiad) => { return (
       
            <div className="col-md-3 mt-2" >
                        <div className="card mb-4">
                        <img  className="card-img-top" src={olympiad.img}   alt={ olympiad.des} />
                            <div className="card-body">
                                <center>
                                <h6 style={{color:"Darkblue"}} className="card-text"> <b>{ olympiad.des} </b></h6>
                                </center>


                                
                                
                                <div className="d-flex justify-content-between align-items-center">
                              
                                        {/* <button type="button" className="btn btn btn-dark">
                                        {website1.workf} 
                                        </button>  */}

                                       
                                    
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={gallery.length}
                /> */}
                            </div>
                            </div>

                          
</>

);
}

export default Gallery2023;