import React from 'react';

import Navbar from '../inc/Navbar';

function Refund (props) {
    return (

        <>
        <Navbar/>
        <br/>
      <br/>
      <br/>
      <br/>
<div className="album py-5">
        
        <div className="container">

        <div className="row">

        <div className="col-md-30">
                    <div className="card mb-4 box-shadow">
                    <div className="card-body">
                    <h5 className="card-text"  style= {{textAlign: "center"}}><b>Cancellation & Refund Policy</b></h5>
                    <br/>

<p className="card-text"> i. Candidature will be cancelled if anything wrong or foul information found in the Registration Form. <br/>
ii. Registration fees once paid will not be refunded in any case.  <br/>
iii. One may cancel his/her registration but fees will not be refunded.</p>


</div></div>
</div>
</div>
</div>




</div>
                            
        </>

);
}

export default Refund;