import React from 'react';
import PART1 from "../images/CLASS_II_PART_I.pdf";

import PART2 from "../images/CLASS_II_PART_II.pdf";
import PARTIII1 from "../images/CLASS_III_PART_I.pdf";
import PARTIII2 from "../images/CLASS_III_PART_II.pdf";

import PARTIV1 from "../images/CLASS_IV_PART_I.pdf";
import PARTIV2 from "../images/CLASS_IV_PART_II.pdf";





import ScrollToTop from "react-scroll-to-top";




function Syllabus () {
  

    return (
      
<>
<br/>


<ScrollToTop smooth top="100" color="Darkblue" />

<div className="album py-0">
        
        <div className="container">
        <h3 style={{color:"Darkblue"}}><b>PREVIOUS YEAR QUESTION - 2022</b></h3>

          </div>
        </div>

<div className="album py-0">
        
        <div className="container">

            <div className="row">
           
       
            <div className="col-md-4 mt-8" >
                    <div class="card text-dark bg-white mb-3">
  <div className="card-header bg-info">PREVIOUS YEAR QUESTION - 2022</div>
  <div className="card-body">
    <h5 className="card-title">Class-II Question  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
</svg></h5>
<br/>
<div className="d-flex justify-content-between align-items-center">
<a className="btn btn-primary btn-sm" target="_blank" rel="noopener noreferrer" href={PART1} >Part-I pdf <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"white"}} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
</svg></a> 
  
                              
   
<br/>
   

 
         {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book-half" viewBox="0 0 16 16">
  <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
</svg>  */}
             <a className="btn btn-primary btn-sm" target="_blank" rel="noopener noreferrer" href={PART2} >Part-II pdf <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"white"}} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
</svg></a> 
                            
                              </div>
  </div>
</div>
                               
                            </div>
                            <div className="col-md-4 mt-8" >
                    <div class="card text-dark bg-white mb-3">
  <div className="card-header bg-info">PREVIOUS YEAR QUESTION - 2022</div>
  <div className="card-body">
    <h5 className="card-title">Class-III Question <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"white"}} className="bi bi-chevron-double-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
</svg></h5>
<br/>
{/* <h5 className="card-title">Class-II Syllabus</h5> */}


<br/>


<div className="d-flex justify-content-between align-items-center">

<a className="btn btn-primary btn-sm" target="_blank" rel="noopener noreferrer" href={PARTIII1} >Part-I pdf <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"white"}} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
</svg></a> 
   
                              
   

   

 
         {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book-half" viewBox="0 0 16 16">
  <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
</svg>  */}
             <a className="btn btn-primary btn-sm" target="_blank" rel="noopener noreferrer" href={PARTIII2} >Part-II pdf <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"white"}} class="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
</svg></a> 
                            
                              </div>
  </div>
</div>
                               
                            </div>


                            <div className="col-md-4 mt-8" >
                    <div class="card text-dark bg-white mb-3">
  <div className="card-header bg-info">PREVIOUS YEAR QUESTION - 2022</div>
  <div className="card-body">
    <h5 style={{color:"dark"}} className="card-title">Class-IV Question <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"white"}} className="bi bi-chevron-double-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
</svg></h5>
<br/>
{/* <h5 className="card-title">Class-II Syllabus</h5> */}


<br/>


<div className="d-flex justify-content-between align-items-center">

<a className="btn btn-primary btn-sm" target="_blank" rel="noopener noreferrer" href={PARTIV1} >Part-I pdf <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"white"}} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
</svg></a> 
           
   
                              
   

   

 
         {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book-half" viewBox="0 0 16 16">
  <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
</svg>  */}
             <a className="btn btn-primary btn-sm" target="_blank" rel="noopener noreferrer" href={PARTIV2} >Part-II pdf <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"white"}} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
</svg></a> 
                            
                              </div>
  </div>
</div>
                               
                            </div>
                             
                           
                             
                            </div>
                            
                            </div>
                            </div>

</>
        
        );
    }
    
    export default Syllabus;