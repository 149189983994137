import React, { useState, useEffect }  from 'react';

import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from './PaginationOld';
import {db} from '../../firebase'
import Navbar from '../inc/Navbar';
import "./Gallery.css";
import ScrollToTop from "react-scroll-to-top";
import Slider2 from "../inc/Slider2";




function Awards(props) {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(40);
    const [pagination, setPagination] =useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start,  end) =>{
        setPagination({start: start, end: end});
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [award, setAward] = useState([])
    const navigate = useNavigate()

    const awardCollectionRef = collection(db, "award");
    useEffect(() => {

        const getAward = async () => {
            const data = await getDocs(awardCollectionRef);
            setAward(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        }

        getAward()
    }, [])
    return (
        
<>
<Navbar/>
<br/>
<br/>
     <br/>
    
     <div className="album py-0">
        
        <div className="container">

            <div className="row">
              <h5>The Skill Foundation</h5>
            </div>
            </div>
            </div>
    <Slider2/>

            <ScrollToTop smooth top="100" color="Darkblue"/>
{/* <img src={Outdoor} class="img-fluid"   alt="Outdoor"></img> */}




                    <div className="album py-2">
        
        <div className="container">

            <div className="row">
                    <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><Link to="/" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg> Home</Link></li>
    <li className="breadcrumb-item"><Link to="/Gallery" href="#">Gallery</Link></li>
        <li className="breadcrumb-item"><Link to="/Others" href="#">Others</Link></li>
    <li className="breadcrumb-item active" aria-current="page">Award Ceremoney 2023</li>
  </ol>
</nav>
</div>
</div>
</div>
<div className="album py-2">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" class="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                               
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>

<div className="album py-0">
        
        <div className="container">

            <div className="row">
            {award.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) 
      

      
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((olympiad) => { return (
       
            <div className="col-md-6 mt-4" >
                        <div className="card mb-4 border-White box shadow">
                        <img  className="card-img-top" src={olympiad.img}   alt="Floor" />
                            <div className="card-body">
                                <center>
                                <h6 className="card-text"> <b>{ olympiad.title} </b></h6>
                                </center>
<p className="card-text mb-2">{olympiad.dis}</p>

                                
                                
                                <div className="d-flex justify-content-between align-items-center">
                              
                                        {/* <button type="button" className="btn btn btn-dark">
                                        {website1.workf} 
                                        </button>  */}

                                       
                                    
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={award.length}
                /> */}
                            </div>
                            </div>
                          
</>

);
}

export default Awards;