import React from 'react'
import Navbar from '../inc/Navbar'

function Price() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br/>
            <br/>
            <div className="album py-1">
                <div className="container">
                    <center><h3 className='mb-4' style={{color:"darkBlue"}}><b>Pricing</b></h3></center>
                    <br/>
                    <h4>Registration fees for GAMES OLYMPIAD 2024 is Rs 150</h4>
                </div>
            </div>
<br/>

        </div>
    )
}

export default Price