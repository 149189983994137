import React from 'react'
import { Link } from "react-router-dom";
import Logotsf from "../images/tsflogo.jpg";
// import {  Button } from "react-bootstrap";
import "./Navbar.css";

function Navbar() {
  return (
    <div>
      <div className="fixed-top">
      <div className="navcolor">
      <div className="album py-0">
      <div className="container">
     <div className='row'>
          <marquee> <h6 style={{ color: 'white' }} className="mt-2" > Welcome to The Skill Foundation  </h6></marquee>
        </div>
        </div>
        </div>
        </div>






        <nav style={{ color: "white" }} className="navbar navbar-expand-lg navbar-white bg-primary shadow" >
          <div className="container-fluid">
            <Link to="/" className="navbar-brand me-2 mb-0" >
              <img src={Logotsf} className="img-fluid" width="110" height="30" alt="theskillfoundation-logo" />
            </Link>

            <div className="d-grid gap-3"> <button className="btn btn-outline-light btn-sm" type="submit"><small> Visitor Counter</small> <div align='end'>
              <a href="https://www.skillfoundation.org" target="_blank">
                <img src="https://hitwebcounter.com/counter/counter.php?page=9041166&style=0006&nbdigits=9&type=page&initCount=11350" title="skillfoundation" Alt="skillfoundation" border="0" /></a> <small>
              </small></div> </button>
            </div>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto mb-0 mb-lg-0">
                <li className="nav-item">
                  <Link to="/" className="nav-link active" style={{ color: "white" }} aria-current="page" >Home</Link>
                </li>
                <li className="nav-item">
                  <Link to="/aboutus" className="nav-link active" aria-current="page" style={{ color: "white" }}  >About us</Link>
                </li>
                <li className="nav-item">
                  <Link to="/Gallery" className="nav-link active" aria-current="page" style={{ color: "white" }} >Gallery</Link>
                </li>
                <li className="nav-item">
                  <Link to="/GamesOlympiad" className="nav-link active" aria-current="page" style={{ color: "white" }} >Games Olympiad</Link>
                </li>




                <li className="nav-item">
                  <Link to="/contact" className="nav-link active" aria-current="page" style={{ color: "white" }}  >Contact us</Link>
                </li>
              </ul>

              <form className="d-flex">

                <a href="tel: +91 9734869861" className="btn btn-secondary">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                </svg> Call Us</a>
              </form>
            </div>
          </div>
        </nav>
      </div>
    </div>

  )
}

export default Navbar
