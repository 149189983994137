import React from 'react'
import Prizes from "../images/prizeslide.jpg";
import skillprize from "../images/prize.jpg";
import Skill from "../images/speech2skill.jpg";


function Slider2() {
  return (
<div>

<div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={Prizes} className="d-block w-100" alt="Prizes"/>
      <div className="carousel-caption d-none d-md-block">
      <h5 style={{color:"Darkblue"}} >The Skill Foundation</h5>
        <p  style={{color:"Darkblue"}}>Games Olympiad Award Ceremony 2023</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src={skillprize} className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
      <h5 style={{color:"Darkblue"}}>The Skill Foundation</h5>
        <p  style={{color:"Darkblue"}} >Games Olympiad Award Ceremony 2023</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src={Skill} className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5>The Skill Foundation</h5>
        <p>Games Olympiad Award Ceremony 2023</p>
      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Slider2;