
import React from "react";
import Pdf from "react-to-pdf";


const ref= React.createRef();

const Pdffile = (props) => {
    return(
        <>
        <div className="Post" ref={ref}>
        <h1>{props.title}</h1>
         <img src={props.image} alt={props.title}/>
         <p>{ props.content}</p>

        </div>
        <Pdf targetRef={ref} filename="post.pdf">
           
            {({ toPdf }) => <button  onClick={toPdf} className="btn btn-primary"> Download Your Application</button>}
          
        </Pdf>
        <br/>
        </>
    );
}

export default Pdffile;
