import React from 'react';

import Navbar from '../inc/Navbar';

function Shipping (props) {
    return (

        <>
        <Navbar/>
        <br/>
      <br/>
      <br/>
<div className="album py-5">
        
        <div className="container">

        <div className="row">
        <div className="col-md-30">
                    <div className="card mb-4 box-shadow">
                    <div className="card-body">
                    <h5 className="card-text"  style= {{textAlign: "center"}}><b>Shipping & Delivery</b></h5>
                    <br/>
                            <h6 className="card-text"> The Skill Foundation. 
                             </h6>
                             <p className="card-text"><b>1. shipping / Delivery</b></p>
<p className="card-text">i. We have no products. So we don't have shipping system.</p>

</div></div>
</div>
</div>
</div>




</div>
                            
        </>

);
}

export default Shipping;