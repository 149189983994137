import React from 'react'
import Navbar from './../inc/Navbar';
import Syllabus from './Syllabus';
// import brochure2nd from "../images/brochure2nd.jpg";
// import Brochure from "../images/FinalBrochure2023.jpg";
import ScrollToTop from "react-scroll-to-top";
// import { EXCEL_FILE_BASE64} from  "../../constants";
// import FileSaver from "file-saver";

import Previous from "../pages/Previous";
import Bracureolympiad from "../images/GAMES Olympiad Brochure 2024.pdf"
import Bracureolympiadresult from "../images/gamesii.pdf"
import Bracureolympiadresultiii from "../images/gamesiii.pdf"
import Bracureolympiadresultiv from "../images/gamesiv.pdf"
import Question2023 from './Question2023';


const CSV_FILE_URL = 'https://www.skillfoundation.org/file_csv.csv'
function Olympiad() {

  const downloadFileAtURL = (url) => {
    const fileName = url.split('/').pop()
    const aTag = document.createElement('a')
    aTag.href = url
    aTag.setAttribute('download', fileName)
    document.body.appendChild(aTag)
    aTag.click();
    aTag.remove();
  };



  //   const handleDownload = () => {

  //   let sliceSize = 1024;
  //   let byteCharacters = atob(EXCEL_FILE_BASE64);
  //   let bytesLength = byteCharacters.length;
  //   let slicesCount = Math.ceil(bytesLength / sliceSize);

  //   let byteArrays = new Array(slicesCount);
  //   for(let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
  //       let begin = sliceIndex * sliceIndex;
  //       let end = Math.min(begin + sliceSize, bytesLength);
  //       let bytes = new Array (end - begin);
  //       for (var offset = begin, i = 0; offset< end; ++i, ++offset){
  //           bytes[i] = byteCharacters [offset].charCodeAt(0);
  //       }
  //       byteArrays[sliceIndex] = new Uint8Array(bytes);

  //   }
  //   let blob = new Blob(byteArrays, { type: 'application/vnd.ms-excel'});
  //   FileSaver.savesAs(new Blob([blob], {}), 'my-excel-xlsx');
  // };




  return (
    <div>
      <Navbar />
      <br />

      <br />
      <br />


      <br />
      <div className="p-2 mb-0 bg-primary text-dark">
        <br />
        <br />
        <div className="d-flex justify-content-between">

          {/* <a className="btn btn-primary btn-sm" target="_blank" rel="noopener noreferrer" href={Bracureolympiad} >BROCHURE DOWNLOAD <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
</svg></a>  */}

{/* <button onClick={() => {downloadFileAtURL (CSV_FILE_URL)}} className="btn btn-primary btn-sm" >RESULT <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
</svg></button>  */}


        </div>
        <br />
      </div>
      <ScrollToTop smooth top="100" color="Darkblue" />
      <div className="album py-2">

        <div className="container">

          <div className="row">
            <div className="col-md-3 mt-8" >
              <div className="d-grid gap-3">
                <a className="btn btn-warning btn-sm mb-2" target="_blank" rel="noopener noreferrer" href={Bracureolympiad} >BROCHURE DOWNLOAD <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "white" }} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                </svg></a>
              </div>
            </div>
            
                     
            {/* <div className="col-md-2 mt-8" >
              <div className="d-grid gap-2">
                <a className="btn btn-outline-success btn-sm mb-2" target="_blank" rel="noopener noreferrer" href={Bracureolympiadresultiv} >Result IV <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "white" }} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                </svg></a>
              </div>
            </div> */}

            {/* <div className="col-md-2 mt-8" >
<div className="d-grid gap-3">
 <button onClick={() => {downloadFileAtURL (CSV_FILE_URL)}} className="btn btn-info btn-sm" >RESULT <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"white"}} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
</svg></button> 
</div>
</div> */}


          </div>
        </div>
      </div>
      <div className="album py-1">

        <div className="container">

          <div className="row">

            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">ABOUT GAMES OLYMPIAD</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">PREVIOUS YEAR QUESTION</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">SYLLABUS OF GAMES OLYMPIAD</button>
              </li>


            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div className="abouthead">
                  <br /><br />
                  <h1 style={{ textAlign: "center", }}> <b>ABOUT GAMES OLYMPIAD </b></h1>
                  <br />
                  <p className="texts"><b>T</b>

                    he Skill Foundation is an Educational Trust popularizing academic competition and assisting development of competitive spirit among school children. GAMES (General Ability in Mathematics, English and Science) Olympiad is one of the main objectives of The Skill Foundation with the aim to promote scientific attitude and temperament through innovative activities in Mathematics, English, Science, Social Studies and General Knowledge. All activities of the Foundation invariably aim at making learning an interesting and interactive process in which the learner is actually able to put his / her skills, memory, talent and knowledge to test. Main aim of GAMES Olympiad is to identify and nurture future scientists, technologists, ITs and other professional talents at school level. It encourages students' participation in national/international competitions/ programs/events disseminate knowledge and information on the latest developments in science technology and other subjects. We strongly believe that such Olympiads, apart from bringing out the best in the participants, also help them gain confidence for competing with the best around them. Also, recognition of merit at an early age is bound to infuse a certain sense of achievement that encourages them to do even better as they advance in age. The students are exposed to a variety of complex problems which demand a high level of analytical ability and proficiency in the use of various concepts from a cross-section of subjects, thereby invoking ingenuity in dealing with them.

                    <br />
                    Participants of GAMES Olympiad are ranked on the basis of marks obtained. Each student will be provided a Student Performance Report (SPR). The objective of SPR is to share with the student and her / his parents, analysis of student’s answers. This is expected to enable the student understand her/his strengths and areas requiring improvement in absolute terms and also on a competitive scale consisting of lacks of students of her/his class who appeared for the Olympiad. Prizes are announced annually which include cash awards, medals, trophies and certificates.
                    <br />
                    A student studying in any School affiliated by WBBPE, WBBSE, CBSE, ICSE or any other board of Class-II, III, IV & V and must having age according to RTE Act 2009 can participate for this examination in their respective class. The syllabus of the Olympiads is the syllabus as prescribed by the WBBPE, WBBSE, CBSE, ICSE and various state boards. GAMES Olympiad is conducted at single level. The examination is an Objective (MCQ-type) Test will be conducted in one half. Medium of instructions of question paper is in <b> Bengali and English language.</b>
                    <br />






                    {/* <React.Fragment>
              

                    <div className="row">
                        <div className="col-sm-8 text-success">

                            <p className="p-1 fw-bold text-dark">

                            Examination Schedule:
                                </p>
                                <table className="table table-bordered text-dark">
                                    <thead>
                                        <tr>
                                        <center>
                                            <th>Date Of Exam</th>    </center>
                                            <th>Time</th>
                                            <th>Email Id</th>
                                            <th>Address</th>
                                            <th>Satus</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                         
                                                <tr key="dnsg"> <center><b>17.12.2023</b></center>
                                                
                                                <td>11:45 am - 02:00pm </td>
                                                <td>xdfvds </td>
                                                <td>df </td>
                                                
                                                </tr> 

                                                 
                                                      
                                            

                                            

                                            
                                           
                                            
                                        </tbody>
                                            
                                    </table>
                            </div>
                        </div>


                    
            </React.Fragment> */}
                  </p>




                </div>
                {/* <img src={Brochure} className='img-fluid' alt="brochure"/> */}
                {/* <img src={brochure2nd} className='img-fluid' alt="brochure2nd"/> */}



              </div>
              <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">




                <br />

                

                <Previous />
                <Question2023/>

              </div>
            </div>


          </div>


        </div></div>

      <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
        <Syllabus />
      </div>




      <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">

      </div>

    </div>








  )
}

export default Olympiad
