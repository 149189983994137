import React, { Component } from 'react';
import Pdffile from "./Pdffile";

class Post extends Component {
        state = {
            title: '',
            content: '',
            image: '',
            postSubmitted: false
        }

        onChange = input => e => {
            this.setState({
                [input]: e.target.value
            });
        }
        
        submitPost = (e) => {
            if(!this.state.title || !this.state.content){
                alert('All fields are required!');
                e.preventDefault();
            
            }else{
                this.setState({
                    postSubmitted: true
                });
            }
        }

        render(){
            return(
                <>
                { !this.state.postSubmitted ? 
             ( <div className="container">
                <div className="jumbotron mt-3">
                    <div className="row">
                        <div className="col-md-12">
                        <div className="well well-sm">
                            <form className="from-horizontal" method="post">
                                <fieldset>
                                    <legend className=" text-center header">Add New Post</legend>
                                    <div className="form-group mb-4">
                                        <span className="col-md-1 col-md-offset-2 text-center "><i className="fa fa-user bigicon"></i></span>
                                        <input name="title" onChange={this.onChange('title')} type="text" placeholder="Post title" className="form-control"/>
                                    </div>
                                    <div className="form-group mb-4">
                                        <span className="col-md-1 col-md-offset-2 text-center "><i className="fa fa-user bigicon"></i></span>
                                        <input onChange={this.onChange('image')} name="image" type="text" placeholder="https://" className="form-control"/>
                                    </div>

                                    <div className="form-group mb-4">
                                        <span className="col-md-1 col-md-offset-2 text-center"><i className="fa fa-pencil-square-o bigicon"></i></span>
                                        <textarea onChange={this.onChange('content')} name="content" type="text" placeholder="Enter  your text here" className="form-control" rows="7"></textarea>
                                    </div>

                                    <div className="form-group">
                                        <center>
                                        <button onClick={this.submitPost} type="button" className="btn btn-primary btn-lg">Submit</button>
                                        </center>
                                    </div>

                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
              </div>
            </div>) : (
                <Pdffile title={this.state.title} content={this.state.content} image={this.state.image} />
            )
            }
<br/>

                </>
            )
        }
}

export default Post;
