import React from 'react'
import Navbar from './../inc/Navbar';
import Slider from './../inc/Slider';

import Aboutpage from './Aboutpage';
// import Upload from './../pages/Upload';
// import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import Ann from "../pages/Ann";
import Olympiadbengali from "../images/GAMES Olympiad Bengali Brochure 2024.pdf";
import Olympiadenglish from "../images/GAMES Olympiad Brochure 2024.pdf";


import ReactPlayer from "react-player";

import ScrollToTop from "react-scroll-to-top";
import "./Home.css";
import Gallery2023 from './Gallery2023';
// import Gallery from './Gallery';

function Home() {
  return (
    <div>
      <Navbar />
      <br />
      <br />


      <div className="album py-3">
        <div className="container">

          <div className="row">
            <h6><b>The Skill Foundation</b></h6>
          </div>
        </div>
      </div>

      <ScrollToTop smooth top="100" color="Darkblue" />
      <Slider />
      {/* <Pdfview/> */}
      {/* <Students/> */}

      <div className="homecolor">

        <Ann />

      </div>
      <div className="album py-2">
        <div className="container">
          <div className="row">
            <div className="col-md-9 mt-3">
              <div class="card border-priamry text-white mb-3" >
                <div class="card-header"><h4 style={{ color: "maroon" }}><center><b> GAMES OLYMPIAD 2024</b></center></h4></div>
                <div class="card-body ">
                  {/* <a href='https://sites.google.com/view/gamesolympiad' target='_blank' rel="noopener noreferrer">
                    <div class="btn-grad16  mb-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg> View & Download Individual Marksheet  </div></a > */}

                  <div className="btn-grad29  mb-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg> Games Olympiad Online Form Fill-Up 2024 Closed </div>



                  {/* <a className="btn btn-warning btn-sm mb-2 me-2 " target="_blank" rel="noopener noreferrer" href={Bracureolympiad} >BROCHURE DOWNLOAD <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "white" }} className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
</svg></a> */}


                  {/* <a className="btn btn-warning btn-sm mb-2 me-2 " href={Guidelines}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
</svg> Guidelines </a> */}


                  {/* <a className="btn btn-warning btn-sm mb-2 me-2 " target="_blank" rel="noopener noreferrer" href={Howtoapply}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
</svg> How To Apply</a> */}


                  {/* <div className="d-grid gap-3">
<Link to="/Application-over" className="btn btn-success  me-2 mb-2" target="_blank" rel="noopener noreferrer" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
</svg> Apply Offline</Link>
</div> */}

                  <div className="d-grid gap-3">
                    <a className="btn btn-danger btn-sm mb-2" href="https://student.skillfoundation.org/Login.aspx" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg> Students Login</a>
                  </div>





                  {/* <a className="btn btn-info me-2 mb-2" href="https://www.skillfoundation.org/GamesOlympiad" target="_blank" rel="noopener noreferrer"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg> Syllabus </a> */}
                  <a className="buttonhome me-2 mb-2" href={Olympiadbengali} target="_blank" rel="noopener noreferrer"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg> <small>Games Olympiad Brochure Download Bengali</small> </a>

                  <a className="buttonhome me-0 mb-2" href={Olympiadenglish} target="_blank" rel="noopener noreferrer"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg><small> Games Olympiad Brochure Download English</small></a>
                  {/* <a className="btn btn-info me-2 mb-2" href="syllabus-of-gamesolympiad" target="_blank" rel="noopener noreferrer"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg> Previous Year Question</a> */}

                  <a href='syllabus-of-gamesolympiad'>
                    <div class="btn-grad15  mb-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg>  Revised Syllabus 2024</div></a>
                  <a href='previous-year-question'>
                    <div class="btn-grad15  mb-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg> Previous Year Question</div></a>







                </div>
              </div>
            </div>

            <div className="col-md-3 mt-3">
             
              <div className="card border-primary mb-3" >

                <div className="card-header"><center>Archive</center></div>
                <div className="card-body text-white">
                  <a href='marksheet'>
                    <div class="btn-grad15  mb-3"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg> Previous Year Result</div></a>

                  <a href='book' className="btn btn-info me-2 mb-2" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg> Preparation, Study Materials & Books </a>
                  <a className="btn btn-info me-2 mb-2" href="tel: +91 9734869861"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg> For Any Enquiry Click Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Aboutpage />
      {/* <AddBook/> */}

      {/* <Syllabus/> */}

      {/* <div className="album py-2">
        <div className="container">

            <div className="row">
          
            <div className="card mb-4 border-White box shadow">
<Video  loop muted onCanPlayThrough={()=>{
    console.log('video play')
}}>
<source src={Examolympiad}  type="video/webm"/>

</Video>
<div className="card-body">
<h5>GAMES OLYMPIAD EXAM TIME </h5>
</div>
</div>
</div>
</div>
</div> */}

      <div className="Galleries">

        <center>
          <h1><b>THE SKILL FOUNDATION </b></h1>
          <h6>Games Olympiad Prize Distribution 2024</h6>
        </center>
        <br />
        <br />
      </div>
      {/* <Gallery/> */}
      {/* <Upload/> */}
      <Gallery2023 />
    </div>

  )
}

export default Home
