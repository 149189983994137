import React from 'react'
import Navbar from './../inc/Navbar';
import Gallery from './Gallery';
import {Link} from "react-router-dom";

function Gallerypage() {
  return (
    <div>
      <Navbar/>
      <br/>
      <br/>
 
      <div className="Galleries">
  

  <center>
  <h1><b>GAMES OLYMPIAD EXAM</b></h1>
  <h6>Gallery</h6>
  </center>
</div>
<br/>
      <div className="album py-0">
        
        <div className="container">

            <div className="row">
                    <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><Link to="/" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg> Back</Link></li>
    <li className="breadcrumb-item"><Link to="/awardceremony2023" href="#">Award Ceremoney 2023</Link></li>
    <li className="breadcrumb-item"><Link to="/Others" href="#">Others</Link></li>
    <li className="breadcrumb-item active" aria-current="page">Gallery</li>
  </ol>
</nav>
</div>
</div>
</div>
      <Gallery/>
    </div>
  )
}

export default Gallerypage
